/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"

import Header from "./header"
import Footer from "./Footer";

const Layout = ({ children }) => {

  return (
    <div className='relative'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Teamwide</title>
        </Helmet>


        <div className="relative">

            <div className="hidden lg:block lg:absolute inset-x-0" style={{zIndex: "10"}}>
                <div className='w-full h-32 bg-main-50'></div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#F2F8FC" fill-opacity="1" d="M0,224L120,240C240,256,480,288,720,293.3C960,299,1200,277,1320,266.7L1440,256L1440,0L1320,0C1200,0,960,0,720,0C480,0,240,0,120,0L0,0Z"></path></svg>
            </div>


            <Header />

            <main className='flex flex-col justify-center align-center items-center' style={{zIndex: "20"}}>{children}</main>

            <Footer />
        </div>
    </div>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout
