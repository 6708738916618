import React from "react"

const Hero = () => (
    <div className="mt-2 mx-auto max-w-screen-xl px-4 sm:mt-4 sm:px-6" style={{zIndex: "20"}}>
        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">

                <h2 className="mt-1 text-4xl tracking-tight leading-10 font-extrabold text-charcoal-600 sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
                    Explore how
                    <br className="hidden md:inline"/>
                    <div className="text-main-700">software teams work</div>
                </h2>
                <p className="mt-3 text-base text-charcoal-400 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    Once a month I send an email called Team Talk where I share all my findings about software teams.
                    I talk to guys who lead teams, are in teams or wrote about teams.
                    Then the best findings I bring to you.
                </p>
                <div className="mt-5 sm:mx-auto sm:text-center lg:text-left lg:mx-0">
                    <form action="http://talk.teamwide.com/add_subscriber" id="revue-form" method="post" target="_blank" className="mt-3 sm:flex">
                        <input aria-label="Email"
                               type="text"
                               name="member[first_name]"
                               className="appearance-none block mt-3 w-full sm:w-32 px-3 py-3 border border-gray-300 text-base leading-6 rounded-md placeholder-gray-500 shadow-sm focus:outline-none focus:placeholder-gray-400 focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out  sm:mt-0"
                               placeholder="First Name"/>

                        <input aria-label="Email"
                               type="email"
                               name="member[email]"
                               className="appearance-none block mt-3 w-full px-3 py-3 border border-gray-300 text-base leading-6 rounded-md placeholder-gray-500 shadow-sm focus:outline-none focus:placeholder-gray-400 focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-1"
                               placeholder="Email"/>
                        <button type="submit"
                                className="mt-3 w-full px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-main-700 shadow-sm hover:bg-main-600 focus:outline-none focus:shadow-outline active:bg-gray-900 transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto">
                            Join Team Talk
                        </button>
                    </form>
                    <p className="mt-3 text-sm leading-5 text-charcoal-300">
                        No spam promise. I will treat your email address like I would mine.
                    </p>
                </div>
            </div>
            <div
                className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">

                <div className="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
                    <button
                        className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
                        <img className="w-full"
                             src="https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
                             alt="Woman making a sale"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
);

export default Hero;