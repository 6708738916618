import React from "react"

import Layout from "../components/layoutIndex"
import Hero from "../components/hero";

const IndexPage = () => (
    <Layout>
            {/* bg-main-55 text-main-5 px-6 py-4 */}
        {/*
        <div className="hidden lg:block lg:absolute lg:inset-0">
            <svg className="absolute top-36 left-1/2 transform translate-x-64 -translate-y-8" width="640" height="540"
                 fill="none" viewBox="0 0 640 784">
                <defs>
                    <pattern id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047" x="118" y="0" width="20" height="20"
                             patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor"/>
                    </pattern>
                </defs>
                <rect y="72" width="640" height="640" className="text-gray-50" fill="currentColor"/>
                <rect x="118" width="404" height="784" fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)"/>
            </svg>
        </div>
        */}

        <Hero />

        <div className="mt-6" style={{zIndex: "20"}}>
            <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-screen-xl lg:px-8">
                <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                    <div>
                        <div className="flex justify-center mt-12 lg:mt-24">
                             <div className="flex items-center justify-center h-24 w-24 rounded-full bg-main-50 text-white">
                                <svg className='h-12 w-12 fill-current text-main-700' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                    <path
                                        d="M528 64H48a48 48 0 0 0-48 48v288a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48V112a48 48 0 0 0-48-48zm-176 76a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12zm100 84a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12zm-84 12v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm-112-96a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12zm16 96v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm-112-96a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12zm16 96v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zM64 140a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12v40a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12zm64 232a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm288 0a12 12 0 0 1-12 12H172a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h232a12 12 0 0 1 12 12zm96 0a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm0-192a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12z"
                                        className="fill-current text-main-400"/>
                                    <path
                                        d="M164 224h-40a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12h40a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12zm96 0h-40a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12h40a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12zm96 0h-40a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12h40a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12zm108 52v-40a12 12 0 0 0-12-12h-40a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12h40a12 12 0 0 0 12-12zm36 44h-40a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12h40a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12zm0-192h-40a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12h40a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12zm-136 64h40a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12h-40a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12zm-96 0h40a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12h-40a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12zm-96 0h40a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12h-40a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12zm-96 0h40a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12H76a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12zm40 128H76a12 12 0 0 0-12 12v40a12 12 0 0 0 12 12h40a12 12 0 0 0 12-12v-40a12 12 0 0 0-12-12z"
                                        className="fill-current text-main-700"/>
                                </svg>
                            </div>
                        </div>
                        <div className="mt-6 flex flex-col items-center justify-center">
                            <h5 className="text-2xl leading-6 font-medium text-charcoal-600">Writing</h5>
                            <p className="mt-6 text-base leading-6 text-charcoal-400 px-2 md:px-28 text-center">
                                In-depth and well researched writing on software teams.
                            </p>
                            <div className="mt-4">
                                <a className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 tracking-wider font-medium rounded-md text-main-800 bg-main-100 hover:bg-main-50 focus:outline-none focus:border-main-300 focus:shadow-outline-main active:bg-main-200 transition ease-in-out duration-150"
                                   href="/writing">
                                    READ ARTICLES
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="flex justify-center  mt-12 lg:mt-24">
                            <div className="flex items-center justify-center h-24 w-24 rounded-full bg-main-50 text-white">

                                <svg className='h-12 w-12 fill-current text-main-700'  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">

                                    <path
                                        d="M64 240a64 64 0 1 0 64 64 64.06 64.06 0 0 0-64-64zm88 80h48v-32h-48zm294.4-106.8l19.2 25.6 48-36-19.2-25.6zM576 64a64 64 0 1 0 64 64 64.06 64.06 0 0 0-64-64z"
                                        className="fill-current text-main-500"/>
                                    <path
                                        d="M576 384a63.84 63.84 0 0 0-38.3 13l-96-57.6a109.91 109.91 0 0 0 6.3-35.5 111.94 111.94 0 0 0-112-112 108.64 108.64 0 0 0-24.4 2.9l-40.8-87.4A63.84 63.84 0 1 0 224 128c1.1 0 2.1-.3 3.2-.3l41 87.8C241.5 235.9 224 267.8 224 304a111.71 111.71 0 0 0 193.2 76.7l95.8 57.5a63.87 63.87 0 1 0 63-54.2zm-240-32a48 48 0 1 1 48-48 48 48 0 0 1-48 48z"
                                        className="fill-current text-main-700"/>
                                </svg>

                            </div>
                        </div>
                        <div className="mt-6 flex flex-col items-center justify-center">
                            <h5 className="text-2xl leading-6 font-medium text-charcoal-600">Tools</h5>
                            <p className="mt-6 text-base leading-6 text-charcoal-400 px-2 md:px-28 text-center">
                                I create free tools which help your team organize better.
                            </p>
                            <div className="mt-4">
                                <a className="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 tracking-wider font-medium rounded-md text-main-800 bg-main-100 hover:bg-main-50 focus:outline-none focus:border-main-300 focus:shadow-outline-main active:bg-main-200 transition ease-in-out duration-150"
                                   href="/tools">
                                    USE TOOLS
                                </a>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>


    </Layout>
);

export default IndexPage
